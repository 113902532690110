/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import axios from "axios";
import OrderdatetimeSlot from "./OrderdatetimeSlot";
import { connect } from "react-redux";
import Slider from "react-slick";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { format } from "date-fns";
var base64 = require("base-64");
import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  brandUrl,
  siteURL,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_NORMAL_POPUP,
  GET_CARTITEMCOUNT,
  GET_STATIC_BLOCK,
  GET_BRANDS,
  GET_ALLUSERSECADDRDATA,
  GET_SITE_LOCATION,
  GET_BANNER_LIST,
} from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  getReferenceID,
  stripslashes,
} from "../Helpers/SettingHelper";

import greentick from "../../common/images/grenn-tick.png";
import newletterImg from "../../common/images/newletter-bg.jpg";
import brandImg from "../../common/images/tusta.jpg";
import noimage from "../../common/images/no-img-product.png";
import dealsplace from "../../common/images/deals-place.jpg";
import groupred from "../../common/images/group-red.png";
import elipse from "../../common/images/elipse.png";
import deal from "../../common/images/updeal.png";

var Parser = require("html-react-parser");

class Home extends Component {
  constructor(props) {
    super(props);
    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    var availability = cookie.load("defaultAvilablityId");
    var shopType = "";
    if (
      cookie.load("shopType") !== "" &&
      typeof cookie.load("shopType") !== undefined &&
      typeof cookie.load("shopType") !== "undefined"
    ) {
      shopType = cookie.load("shopType");
    } else {
      shopType = 1;
    }

    const deliveryPostalCode =
      cookie.load("deliveryPostalCode") !== "" &&
      typeof cookie.load("deliveryPostalCode") !== undefined &&
      typeof cookie.load("deliveryPostalCode") !== "undefined"
        ? cookie.load("deliveryPostalCode")
        : "";

    this.state = {
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      home_about_us: "",
      orderTypeBlks: "",
      ourStoryBlck: "",
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      cartitemcount: 0,
      cartDetails: "",
      guestname: "",
      guestname_error: "",
      siteLocationList: [],
      displaySiteLocation: "",
      locationImage: [],
      currentPage: this.props.match.path,
      sitebrands: [],
      brandmainList: [],
      defaultAvilTy: availability,
      shopType: shopType,
      seletedAvilablityId_home: "",
      seletedOutletId_home: "",
      order_tat_time_home: 0,
      globalsettings: [],
      secondaryaddresslist: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      orderHandled: "",
      orderDeliveryAddress: "",
      nextavail: "",
      getDateTimeFlge: "",
      deliveryInfohome: [],
      selectedAvil: "No",
      cartOutletList: [],
      selectPostalCode_home: "Yes",
      selectPickupTime: "No",
      deliveryPostalCode: "",
      deliveryPostalCode_popup: "",
      tag: [],
      getDateTimeFlg: "",
      seleted_ord_date: "",
      seleted_ord_time: "",
      siteLocation: "",
      displayBrands: "",
      hometakeawaypopup: 0,
      deliveryPostalCode_home: "",
      showPopupButton: "",
      banner: [],
      bannerimagesource: "",
      deals_banner: "",
    };

    this.props.getSettings();
    this.props.getNormalPopup();
    this.props.getCartItemCount();
    this.props.getStaticBlock();
    this.props.getloadSiteLocation();
    this.props.getBrands();
    this.props.getSecondaryAddress();
    this.props.getBannerList();
  }

  componentWillReceiveProps(PropsData) {
    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();

          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }

          cookie.save("mailpopopuptrg", "No", { path: "/" });

          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }
    if (PropsData.cartitemcount !== this.state.cartitemcount) {
      var cartitemcount = 0;
      if (Object.keys(PropsData.cartitemcount).length > 0) {
        if (PropsData.cartitemcount[0].status === "ok") {
          cartitemcount = PropsData.cartitemcount[0].result_set.cart_items;
        }
      }
      this.setState({ cartitemcount: cartitemcount });
    }

    if (PropsData.siteLocationList !== this.state.siteLocationList) {
      if (Object.keys(PropsData.siteLocationList).length > 0) {
        if (PropsData.siteLocationList[0].status === "ok") {
          this.setState(
            {
              siteLocationList: PropsData.siteLocationList[0].result_set,
              locationImage: PropsData.siteLocationList[0].common,
            },
            function () {
              this.displaySiteLocation();
            }
          );
        }
      }
    }

    if (PropsData.staticblack !== this.state.footerBlocks) {
      var home_about_us = "";
      var deals_banner = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "home-about-us") {
            home_about_us = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "deals-banner") {
            deals_banner = data.staticblocks_description;
            return "";
          }
        });
      }

      this.setState({
        home_about_us: home_about_us,
        deals_banner: deals_banner,
      });
    }

    if (PropsData.sitebrands !== this.state.brandmainList) {
      if (Object.keys(PropsData.sitebrands).length > 0) {
        this.setState(
          {
            brandmainList: PropsData.sitebrands,
            sitebrands: PropsData.sitebrands[0].result_set,
            tag: PropsData.sitebrands[0].tag,
          },
          function () {
            this.displayBrands();
          }
        );
      }
    }

    if (PropsData.banner !== this.state.banner) {
      if (Object.keys(PropsData.banner).length > 0) {
        if (PropsData.banner[0].status === "ok") {
          var bannerlist = PropsData.banner[0].result_set;
          var bannerimagesource = PropsData.banner[0].common.image_source;
          this.setState({
            banner: bannerlist,
            bannerimagesource: bannerimagesource,
          });
        }
      }
    }
  }

  loadTag(tag_id) {
    if (tag_id !== "" && tag_id !== null && tag_id !== undefined) {
      let tagID = tag_id.split(",");
      let tagList = this.state.tag;
      if (tagID.length > 0) {
        return tagID.map(function (item, index) {
          if (
            tagList[item] !== "" &&
            typeof tagList[item] !== undefined &&
            typeof tagList[item] !== "undefined"
          ) {
            return (
              <span key={index}>
                {tagList[item]}
                {tagID.length - 1 != index ? ", " : ""}
              </span>
            );
          }
        });
      }
    }
  }

  checkAvailabilityBrand(brand_slug, brand_id) {
    //   var orderOutletId = cookie.load("orderOutletId");

    //   if (orderOutletId === "" || orderOutletId === undefined) {

    //     cookie.save("home_brand_id", brand_id);
    //     cookie.save("brand_slug", brand_slug);

    //    $.magnificPopup.open({
    //       items: {
    //         src: "#awesome-popup",
    //       },
    //       type: "inline",
    //     });
    //     return false;

    //   }else{

    // }

    if (brand_slug !== null && brand_slug !== "" && brand_id !== "") {
      cookie.save("home_brand_id", brand_id);
      cookie.save("brand_slug", brand_slug);

      location.href = siteURL + "brands/" + brand_slug;
    }
  }

  displayBrands() {
    if (
      Object.keys(this.state.sitebrands).length > 0 &&
      this.state.sitebrands !== undefined &&
      this.state.sitebrands !== null
    ) {
      var i = 0;

      if (this.state.sitebrands.length >= 6) {
        i++;
      }

      var brands_list = this.state.sitebrands.map((item, index) => {
        return (
          <li>
            <a
              onClick={this.checkAvailabilityBrand.bind(
                this,
                item.brand_slug,
                item.brand_id
              )}
            >
              <div className="market-image">
                <img
                  src={
                    item.brand_active_image !== ""
                      ? brandUrl + item.brand_active_image
                      : brandImg
                  }
                  alt=""
                />
              </div>
              <div className="market-name">
                <h4>{item.brand_name}</h4>
                <p>{item.brand_tags !== "" && this.loadTag(item.brand_tags)}</p>
              </div>
            </a>
          </li>
        );
      });

      if (i > 6) {
        $(".btm-load-more").show();
      } else {
        $(".btm-load-more").hide();
      }

      this.setState({ displayBrands: brands_list }, function () {
        if (this.props.match.path === "/shop") {
          if ($("#home_brands").length > 0) {
            setTimeout(function () {
              $("html, body").animate(
                {
                  scrollTop: $("#home_brands").offset().top - 80,
                },
                1000
              );
            }, 2000);
          }
        }
      });

      return brands_list;
    }
  }

  checkAvailabilityHome(location_slug, sl_location_id) {
    var orderOutletId = cookie.load("orderOutletId");

    if (orderOutletId === "" || orderOutletId === undefined) {
      cookie.save("mall_slug", location_slug);
      cookie.save("sl_location_id", sl_location_id, { path: "/" });

      $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });

      return;
    } else {
      cookie.save("mall_slug", location_slug);
      cookie.save("sl_location_id", sl_location_id, { path: "/" });

      this.props.history.push("/mall/" + location_slug);
    }
  }

  displaySiteLocation() {
    if (this.state.siteLocationList.length > 1) {
      var result = this.state.siteLocationList.map((item, index) => {
        if (item.sl_slug !== "" && item.sl_slug !== null) {
          return (
            <li key={index}>
              <div className="market-item">
                <div className="market-image">
                  {item.sl_slug !== "" && item.sl_slug !== null ? (
                    <a
                      onClick={this.checkAvailabilityHome.bind(
                        this,
                        item.sl_slug,
                        item.sl_location_id
                      )}
                    >
                      <img
                        src={
                          item.sl_image !== ""
                            ? this.state.locationImage.image_source +
                              item.sl_image
                            : noimage
                        }
                      />
                    </a>
                  ) : (
                    <a>
                      <img
                        src={
                          item.sl_image !== ""
                            ? this.state.locationImage.image_source +
                              item.sl_image
                            : noimage
                        }
                      />
                    </a>
                  )}
                </div>
                <div className="market-name">
                  <a>
                    <h4>{stripslashes(item.sl_name)}</h4>
                    <div className="loc-with-km">
                      <span>{stripslashes(item.sl_pickup_address_line1)}</span>
                      <span>{item.sl_kilo_meters}</span>
                    </div>
                  </a>
                </div>
              </div>
            </li>
          );
        }
      });

      this.setState({ displaySiteLocation: result }, function () {
        this.loadShop();
      });
    }
  }

  loadShop() {
    /* if (this.props.match.path === "/shop") {
      if ($("#home_markets").length > 0) {
        $("html, body").animate(
          {
            scrollTop: $("#home_markets").offset().top - 80,
          },
          1000
        );
      }
    } */
  }

  loadGruopShare() {
    showLoader("sharegroup-body", "class");
    $("#sharegroup-popup").modal({ backdrop: "static", keyboard: false });
    var current = this;
    axios
      .get(
        apiUrl +
          "cart/contents?app_id=" +
          appId +
          "&status=A&cart_id=" +
          base64.decode(this.props.match.params.orderID) +
          "&outlet_slug=" +
          this.props.match.params.slugRestaurant
      )
      .then((res) => {
        if (res.data.status === "ok") {
          if (
            res.data.result_set !== "" &&
            typeof res.data.result_set !== undefined &&
            typeof res.data.result_set !== "undefined"
          ) {
            this.setState(
              { cartDetails: res.data.result_set.cart_details },
              function () {
                hideLoader("sharegroup-body", "class");
              }
            );
          } else {
            $("#sharegroup-popup").modal("hide");
            setTimeout(function () {
              current.loadAlert("Error", "Invalid Group");
            }, 2000);
          }
        } else {
          $("#sharegroup-popup").modal("hide");
          setTimeout(function () {
            current.loadAlert("Error", "Invalid Group");
          }, 2000);
        }
      });
  }

  loadAlert(header, message) {
    /*  $("body").append(
      '<div class="white-popup popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:;" class="popup-modal-dismiss button">OK</a></div></div></div></div></div></div>'
    ); */
  }

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "#" + unitNo : "";
  }
  chooseAvailability(availability) {
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  componentDidMount() {
    cookie.remove("tempOutletLocationId", { path: "/" });
    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", { path: "/" });
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        } else if (odrType === "catering") {
          availabilityId = cateringId;
        } else if (odrType === "reservation") {
          availabilityId = reservationId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          $.magnificPopup.open({
            items: {
              src: "#order-popup",
            },
            type: "inline",
          });
        }
      });

    $("#postalcode_home").keyup(function (e) {
      RctThis.setState({ hometakeawaypopup: 0 });
      var postcode_values = $("#postalcode_home").val();
      if (e.keyCode == 13) {
        if (postcode_values.length >= 6) {
          $(".postal_error_home").hide();
          RctThis.findOutletBasedZonehome(0, deliveryId);
        } else {
          $(".postal_error_home").show();
          $(".postal_error_home").html(
            '<span class="error">' +
              "Please enter valid postal code." +
              "</span>"
          );
        }
      }
    });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0
      //cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    // if (
    //   (cookie.load("promoPopupTrigger") === "Yes" ||
    //     (otherPageActTrigger === "no" &&
    //       this.state.normalpopup_status === "no" &&
    //       Object.keys(this.state.normalpopupdata).length === 0)) &&
    //       cookie.load("mailpopopuptrg") !== "yes"
    // ) {
    //   cookie.save("mailpopopuptrg", "yes", { path: "/" });
    //   cookie.remove("promoPopupTrigger", { path: "/" });
    //   $.magnificPopup.open({
    //     items: {
    //       src: "#promo-check-popup",
    //     },
    //     type: "inline",
    //     midClick: true,
    //     closeOnBgClick: false,
    //   });
    // }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of UP. Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }
  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var orderPostalCode = cookie.load("orderPostalCode");
    var deliveryPostalCode = cookie.load("deliveryPostalCode");

    if (
      defaultAvilTy !== "" &&
      typeof defaultAvilTy !== undefined &&
      typeof defaultAvilTy !== "undefined"
    ) {
      if (availability === deliveryId) {
        if (
          deliveryPostalCode !== "" &&
          typeof deliveryPostalCode !== undefined &&
          typeof deliveryPostalCode !== "undefined"
        ) {
        } else {
          $.magnificPopup.open({
            items: {
              src: "#delevery-postcode-popup",
            },
            type: "inline",
          });
          return false;
        }
      }
      cookie.remove("defaultAvilablityId", { path: "/" });
      cookie.save("defaultAvilablityId", availability, { path: "/" });
      this.setState({ defaultAvilTy: availability }, function () {
        showLoader("innerproduct", "class");
        axios
          .get(
            apiUrl +
              "cart/update_availability?app_id=" +
              appId +
              "&reference_id=" +
              getReferenceID() +
              "&customer_id=" +
              cookie.load("UserId") +
              "&availability_id=" +
              availability
          )
          .then((res) => {});
      });
      //this.props.history.push("events/xmas");
      this.props.history.push("/food");
      return false;
    }

    if (
      defaultAvilTy !== "" &&
      typeof defaultAvilTy !== undefined &&
      typeof defaultAvilTy !== "undefined" &&
      defaultAvilTy === availability
    ) {
      if (availability === deliveryId) {
        if (
          orderPostalCode === "" ||
          typeof orderPostalCode === undefined ||
          typeof orderPostalCode === "undefined"
        ) {
          $.magnificPopup.open({
            items: {
              src: "#delevery-postcode-popup",
            },
            type: "inline",
          });
          return false;
        }
      } else if (availability === pickupId) {
        if (
          orderPostalCode === "" ||
          typeof orderPostalCode === undefined ||
          typeof orderPostalCode === "undefined"
        ) {
          this.setState({ seletedAvilablityId_home: pickupId });
          return false;
        }
      }
      //this.props.history.push("events/xmas");
      this.props.history.push("/food");
      return false;
    } else {
      if (this.state.cartitemcount > 0) {
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      this.setState({ seletedAvilablityId_home: pickupId });
      //popupIdtxt = "#takeaway-popup";
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    if (availability == avlType) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  handleChangeTxt = (item, value, event) => {
    this.setState({
      [item]: event.target.value,
      deliveryPostalCode_popup: event.target.value,
      deliveryPostalCode_home: event.target.value,
      showPopupButton: value,
    });
    if (event.target.value !== "") {
      this.setState({ [item + "_error"]: "" });
    }
  };

  handleChangeTxt_home = (event) => {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;
    $(".postal_error_popup").hide();
    this.setState({ deliveryPostalCode_popup: postalCode }, function () {
      if (postCdLth >= 6) {
        $(".postal_error_home").hide();
        //this.findOutletBasedZonehome(0, deliveryId);
      }
    });
  };

  viewMenu(event) {
    event.preventDefault();
    var error = 0;
    if (this.state.guestname === "") {
      this.setState({ guestname_error: 1 });
      error++;
    }
    if (error === 0) {
      var qs = require("qs");
      var postObject = {
        app_id: appId,
        cart_id: this.props.match.params.orderID,
        cart_member_name: this.state.guestname,
      };
      showLoader("view_menu", "class");
      axios
        .post(apiUrl + "cart/create_group", qs.stringify(postObject))
        .then((response) => {
          hideLoader("view_menu", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            $("#sharegroup-popup").modal("hide");
            cookie.save("cart_id", response.data.cart_details.cart_id, {
              path: "/",
            });
            cookie.save("cart_group_id", response.data.cart_group_id, {
              path: "/",
            });
            cookie.save(
              "orderOutletId",
              base64.decode(this.props.match.params.outletID),
              {
                path: "/",
              }
            );
            cookie.save(
              "defaultAvilablityId",
              response.data.cart_details.cart_availability_id,
              {
                path: "/",
              }
            );
            cookie.save(
              "outletLocationId",
              response.data.cart_details.cart_location_id,
              {
                path: "/",
              }
            );
            this.props.history.push(
              "/" +
                this.props.match.params.slugType +
                "/" +
                this.props.match.params.siteLocation +
                "/" +
                this.props.match.params.slugRestaurant
            );
          } else {
            $("#create-group-error").show();
            $("#create-group-error p").html(response.data.message);
            setTimeout(function () {
              $("#create-group-error").hide();
            }, 3000);
          }
          return false;
        });
    }
  }

  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  /* find Zone*/
  findOutletBasedZonehome(first, availability) {
    var postalcode = "";

    if (first === "1") {
      postalcode = this.state.deliveryPostalCode;
    } else {
      postalcode = this.state.deliveryPostalCode_popup;
    }

    this.setState({ hometakeawaypopup: 1 });

    if (
      postalcode == "" ||
      (postalcode !== "" && parseInt(postalcode.length) <= 5)
    ) {
      $(".postal_error_home").show();
      $(".postal_error_home").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    $(".postal_error_home").hide();
    showLoader("delivery_submit_cls", "class");

    cookie.save("nearest_postal_code", postalcode);
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData = this.state.deliveryInfohome;
      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);

        cookie.save("orderDateTime", orderDateTime, { path: "/" });
        cookie.save("deliveryDate", deliveryDate, { path: "/" });
        cookie.save("deliveryTime", deliveryTime, { path: "/" });

        if (this.state.seletedAvilablityId_home === deliveryId) {
          cookie.save("orderZoneId", orderInfoData["orderZoneId"], {
            path: "/",
          });
          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            { path: "/" }
          );
        }

        cookie.save("orderOutletId", orderInfoData["orderOutletId"], {
          path: "/",
        });
        cookie.save("orderOutletName", orderInfoData["orderOutletName"], {
          path: "/",
        });

        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"], {
          path: "/",
        });
        if (this.state.seletedAvilablityId_home === deliveryId) {
          cookie.save("deliveryPostalCode", orderInfoData["orderPostalCode"], {
            path: "/",
          });
        }
        cookie.save("orderTAT", orderInfoData["orderTAT"], { path: "/" });
        cookie.save("orderHandled", orderInfoData["orderHandled"], {
          path: "/",
        });
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          { path: "/" }
        );
        this.setState({ defaultAvilTy: orderInfoData["defaultAvilablityId"] });
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"], {
          path: "/",
        });
        $.magnificPopup.close();

        this.props.history.push("/marketplace");

        // if (
        //   (this.props.match.params.slugRestaurant !== "" &&
        //     typeof this.props.match.params.slugRestaurant !== undefined &&
        //     typeof this.props.match.params.slugRestaurant !== "undefined") ||
        //   this.props.match.path === "/food"
        // ) {
        //   location.reload();
        //   return false;
        // }
      } else {
        $.magnificPopup.open({
          items: {
            src: "#outlet-error-popup-home",
          },
          type: "inline",
        });
      }
    } else {
      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => (
        <div className="address_linfo" key={typeTxt + "-" + index}>
          <div className="custom_radio">
            <input
              type="radio"
              name={"address_chk" + typeTxt}
              value={addr.postal_code}
              className="address_chk"
              onChange={this.changPostalValue.bind(
                this,
                typeTxt,
                addr.postal_code
              )}
            />
            <span>
              {addressFormat(
                addr.unit_code,
                addr.unit_code2,
                addr.address,
                addr.country,
                addr.postal_code
              )}
            </span>
          </div>
        </div>
      ));

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input
                type="radio"
                name={"address_chk" + typeTxt}
                value=""
                defaultChecked={true}
                className="address_chk"
                onChange={this.changPostalValue.bind(this, typeTxt, "")}
              />
              <span>Enter New Address</span>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  close_popup(event) {
    event.preventDefault();
    $.magnificPopup.close();
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_date: value, seleted_ord_time: ordTime });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_time: tmSltArr["startTime"] });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  changeAvailabilityheader(availability, event) {
    event.preventDefault();

    if (availability === deliveryId) {
      this.setState({
        defaultAvilTy: availability,
        seletedAvilablityId_home: availability,
      });
    } else {
      this.setState({
        defaultAvilTy: availability,
        seletedAvilablityId_home: availability,
      });
    }

    var orderDateTime = cookie.load("orderDateTime");
    if (this.state.defaultAvilTy !== availability) {
      cookie.remove("defaultAvilablityId", { path: "/" });
      cookie.save("defaultAvilablityId", availability, { path: "/" });
      this.setState(
        { defaultAvilTy: availability, cartTriggerFlg: "yes" },
        function () {
          showLoader("innerproduct", "class");
          axios
            .get(
              apiUrl +
                "cart/update_availability?app_id=" +
                appId +
                "&reference_id=" +
                getReferenceID() +
                "&customer_id=" +
                cookie.load("UserId") +
                "&availability_id=" +
                availability
            )
            .then((res) => {
              this.sateValChange(
                "loadOutlet",
                availability + "_" + this.state.deliveryPostalCode
              );
            });
        }
      );
    }
  }

  render() {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        showNormalPopup =
          settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
          "1"
            ? "yes"
            : "";
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }

    var settingsGallery = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
    };

    return (
      <div className="home-main-div">
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          selectedAvil={this.state.seletedAvilablityId_home}
          hometakeawaypopup={this.state.hometakeawaypopup}
          deliveryPostalCode_home={this.state.deliveryPostalCode_home}
        />

        <section className="white-home">
          <div className="wh-rhs-img">
            {Object.keys(this.state.banner).length > 0 ? (
              <Slider {...settingsGallery}>
                {this.state.banner.map((banner_value, index) => {
                  return (
                    <div className="banner-in-img" key={index}>
                      <img
                        src={
                          this.state.bannerimagesource +
                          banner_value.banner_image
                        }
                        alt="Banner"
                      />
                      <div className="bann-container">
                        <div className="bann-containe-inner">
                          <div className="wh-lhs">
                            <div className="wh-lhs-top">
                              <h2>
                                {" "}
                                The brands you love, <br /> delivered as one.{" "}
                              </h2>
                            </div>

                            <div className="wh-lhs-bottom desktop-form">
                              <form>
                                <input
                                  type="text"
                                  onKeyPress={(e) => this.validateIntval(e)}
                                  maxLength="6"
                                  onChange={this.handleChangeTxt.bind(
                                    this,
                                    "deliveryPostalCode",
                                    "popupshow"
                                  )}
                                  placeholder="Enter Postal Code"
                                  value={this.state.deliveryPostalCode}
                                  id="postalcode_home"
                                  className="banner-input"
                                />

                                <button
                                  type="button"
                                  onClick={this.findOutletBasedZonehome.bind(
                                    this,
                                    1,
                                    deliveryId
                                  )}
                                  className="button delivery_submit_cls"
                                >
                                  TAKE ME SHOPPING!
                                </button>
                              </form>
                              <div className="postal_error_home"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
              <div className="loader-main-cls">
                <div className="loader-sub-div"></div>
              </div>
            )}
          </div>
        </section>

        {this.state.home_about_us !== "" && (
          <section className="abt-home">
            {Parser(this.state.home_about_us)}
          </section>
        )}
        <section className="mini-banner-parent rel">
          {this.state.deals_banner !== "" && (
            <Slider {...settingsGallery}>
              {Parser(this.state.deals_banner)}
            </Slider>
          )}
        </section>
        <section className="markets brands-home" id="home_brands">
          <div className="container">
            <h2>Brands</h2>
            {this.state.displayBrands !== "" && (
              <ul>{this.state.displayBrands}</ul>
            )}
            <div className="btm-load-more">
              <Link to="/brandlist" className="button">
                Load More
              </Link>
            </div>
          </div>
        </section>

        <section className="markets" id="home_markets">
          <div className="container">
            <h2>Marketplaces</h2>

            <ul>
              {this.state.displaySiteLocation !== "" &&
                this.state.displaySiteLocation}

              <li>
                <div className="market-item">
                  <div className="market-image">
                    <Link to={"/deals"}>
                      <img src={dealsplace} />
                    </Link>
                  </div>
                  <div className="market-name">
                    <a>
                      <h4>Upshop Deals</h4>
                      <div className="loc-with-km">
                        <span>Orchard</span>
                      </div>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>

        {/* Footer section */}
        <Footer />
        <div
          id="sharegroup-popup"
          className="modal fade commom-modal-topcls sharegroup-popup"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <div className="common-modal-head">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    X
                  </button>
                </div>

                <div className="sharegroup-body textcenter">
                  <div className="sharegroup-body-info">
                    <img src={groupred} alt="" />
                    {this.state.cartDetails !== "" && (
                      <h4>
                        {this.state.cartDetails.customer_first_name}
                        {this.state.cartDetails.customer_last_name !== ""
                          ? " " + this.state.cartDetails.customer_last_name
                          : ""}
                        ’s Order
                      </h4>
                    )}
                    <p>
                      Add your favourite dishes to the basket. Once you add your
                      items to{" "}
                      {this.state.cartDetails !== "" && (
                        <span>
                          {this.state.cartDetails.customer_first_name}
                          {this.state.cartDetails.customer_last_name !== ""
                            ? " " + this.state.cartDetails.customer_last_name
                            : ""}
                          ’s Order
                        </span>
                      )}{" "}
                      , you won't be able to change them.{" "}
                    </p>
                  </div>
                  <div className="sharegroup-order-info">
                    <span>Order Details</span>
                    {this.state.cartDetails !== "" && (
                      <ul>
                        <li>{this.state.cartDetails.outlet_name}</li>
                      </ul>
                    )}

                    <p>
                      Payment will be paid by{" "}
                      {this.state.cartDetails !== "" && (
                        <strong>
                          {this.state.cartDetails.customer_first_name}
                          {this.state.cartDetails.customer_last_name !== ""
                            ? " " + this.state.cartDetails.customer_last_name
                            : ""}
                          ’s
                        </strong>
                      )}
                    </p>
                  </div>
                  <div className="sharegroup-form-info">
                    <span>Your Name</span>
                    <input
                      type="text"
                      className="copy-share-text"
                      value={this.state.guestname}
                      onChange={this.handleChangeTxt.bind(this, "guestname")}
                      maxLength={50}
                    />
                    {this.state.guestname_error !== "" && (
                      <span className="error">This field is required.</span>
                    )}
                    <div className="sharegroup-form-single-btn sharegroup-form-multiple-btn">
                      <a href="#" className="button">
                        Cancel
                      </a>
                      <a
                        href="#"
                        className="button view_menu"
                        onClick={this.viewMenu.bind(this)}
                      >
                        View Menu
                      </a>
                    </div>
                    <div
                      className="custom_alertcls alert-danger alert-dismissible1 success_hide"
                      id="create-group-error"
                      style={{ display: "none" }}
                    >
                      {" "}
                      <p className="jquery-success-msg">
                        Great! Copied Successfully
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_main">
            <div className="normal_popup_heading">
              <h3>
                {stripslashes(this.state.normalpopupdata.normalpopup_title)}
              </h3>
            </div>
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div className="normal_popup_cont">
                  {Parser(this.state.normalpopupdata.normalpopup_description)}
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}
        {/*Promo Check Email popup Start*/}
        <div
          id="promo-check-popup"
          className="white-popup mfp-hide popup_sec promo-check-popup"
        >
          <div className="promopopup-maindiv">
            <div className="promopopup-lhs">
              <div className="frm-action-div">
                <div className="frm-top-title">
                  <h3>Join our email list</h3>
                  <p>Enter your Email address to receive your promocode</p>
                </div>

                <div className="frm-inputbtn-div">
                  <div className="form-group">
                    <div className="focus-out">
                      <label>Email Address</label>
                      <input
                        type="email"
                        className="form-control input-focus"
                        value={this.state.promo_mail_id}
                        onChange={this.handleEmailChange.bind(this)}
                      />
                      {this.state.promomail_error !== "" && (
                        <div id="promomail-error">
                          {this.state.promomail_error}
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={this.sendPromoMailFun.bind(this)}
                    className="button promomailpopup-cls"
                  >
                    Subscribe
                  </button>
                </div>

                <div className="frm-bottom-text">
                  <p>
                    SIGN UP FOR EXCLUSIVE UPDATES, NEW PRODUCTS, AND
                    INSIDER-ONLY DISCOUNTS
                  </p>
                </div>
              </div>
            </div>

            <div className="promopopup-rhs">
              <img src={newletterImg} />
            </div>
          </div>
        </div>
        {/*Promo Check Email popup - End*/}

        {/* success popup - Start */}
        <div
          id="awesome-popup-home"
          className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime"
        >
          <div className="popup_equalrw">
            <div className="popup_right">
              <div className="innervmid_in">
                <div className="popup_switch_availability">
                  <Link
                    className={
                      this.state.defaultAvilTy === deliveryId ? "active" : ""
                    }
                    onClick={this.changeAvailabilityheader.bind(
                      this,
                      deliveryId
                    )}
                  >
                    <span>Delivery</span>
                  </Link>
                  <Link
                    className={
                      this.state.defaultAvilTy === pickupId ? "active" : ""
                    }
                    onClick={this.changeAvailabilityheader.bind(this, pickupId)}
                  >
                    <span>Pickup</span>
                  </Link>
                </div>

                {this.state.seletedAvilablityId_home === deliveryId && (
                  <div className="form-group">
                    <h4 className="uper-light">Your Delivery Location</h4>
                    <div className="focus-out focused">
                      <input
                        type="text"
                        id="postalcode"
                        maxLength="6"
                        className="form-control input-focus"
                        onChange={this.handleChangeTxt_home.bind(this)}
                        value={this.state.deliveryPostalCode_popup}
                      />
                      <div className="postal_error_popup"></div>
                    </div>
                    {this.state.seletedAvilablityId_home === deliveryId && (
                      <div className="greenwith-add">
                        <h5>
                          <img src={greentick} />
                          {this.state.orderDeliveryAddress}
                        </h5>
                      </div>
                    )}
                  </div>
                )}

                <div className="datetime_selt_sec">
                  <div className="datetime_selt_lbl">Date &amp; Time</div>

                  <OrderdatetimeSlot
                    {...this.props}
                    hdrState={this.state}
                    setdateTimeFlg={this.setdateTimeFlg}
                  />

                  <div className="ordrdatetime_error"></div>
                </div>

                <div className="btn_sec">
                  <input
                    type="button"
                    onClick={this.setOrderOutletDateTimeData.bind(this)}
                    className="button"
                    value="Take Me Shopping!"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading")}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var normalPopupStatus = "initial";
  var normalPopupArr = Array();

  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var secondarydataArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
    cartitemcount: state.cartitemcount,
    staticblack: blacksArr,
    sitebrands: state.brands,
    siteLocationList: state.sitelocation,
    banner: state.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getCartItemCount: () => {
      dispatch({ type: GET_CARTITEMCOUNT });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getBrands: () => {
      dispatch({ type: GET_BRANDS });
    },
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
    getloadSiteLocation: () => {
      dispatch({ type: GET_SITE_LOCATION });
    },
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
